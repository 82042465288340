import client, { errorView } from "../../client"

class User {
    static getInfo() {
        return client.get('/cms/api/v1/user').then((response) => {
            return {
                data: response.data,
                status: response.status
            }
        })
        .catch(err => errorView("Get user info", err))
    }

    static setInfo(data) {
        return client.post('/cms/api/v1/user', data)
        .catch(err => errorView("Set user info", err))
    }
}

export default User