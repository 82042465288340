import Timezone from "../../scripts/timezone"
import CMS from "../../service/cms/service"

const getDefaultState = () => {
    return {
        timezones: [],
        countries: [],
    }
}

export default {
    state : getDefaultState(),
    mutations: {
        'set-timezones': (state, timezones) => {
            state.timezones = timezones
        },
        'set-countries': (state, countries) => {
            state.countries = countries
        },
    },
    getters: {
        timezones(state){
            return state.timezones
        },
        getTimezoneByID: (state) => (id) => {
            return state.timezones.find( timezone => timezone.id == id)
        },  
        getCountyByID: (state) => (id) => {
            return state.countries.find( county => county.id == id)
        },  
        countries(state){
            return state.countries
        },

        browserTimezone(state){

			if (state.timezones.length == 0) {
				return undefined
			}

            const tzShortName = Intl.DateTimeFormat().resolvedOptions().timeZone
            return state.timezones
				.find(tz => tz.name.includes(tzShortName))
        }
    },
    actions: { 
        'setup-geo': (context, payload) => {
			
			let {withTimezones, withCountries} = payload ? payload : {}

			if (withCountries == undefined)
				withCountries = true

			if (withTimezones == undefined)
				withTimezones = true

			console.log('withCountries :>> ', withCountries);
			console.log('withTimezones :>> ', withTimezones);
			// debugger



			if (!withTimezones && !withCountries)
				return



			if (withTimezones){
				CMS.geo.timezoneList().then(timezones => {
					if (timezones.err){
						throw new Error(timezones.err)
					}
					context.commit("set-timezones", timezones.map(tzData => {
						return new Timezone(tzData)
					}))
				})
			}

			if (withCountries) {
				CMS.geo.countryList().then(countries => {
					if (countries.err){
						throw new Error(countries.err)
					}
					context.commit("set-countries", countries)
				})
			}
        },
    }
}