import {getColumnWidth} from '../../scripts/column'
import header from './calendar-header-store.js'
import slots from './calendar-slots-store.js'
import templates from './calendar-templates-store.js'
import { dateSlotsToToolbar, delSupportWorkTime, setSupportWorkTime, updSupportWorkTime } from './calendar-table-actions'
import { isEqualDate } from '../../scripts/date'
import { TABLE_MOVEMENT_ACTION_REPLACE } from '../../scripts/tableMovementAction'

const getDefaultState = () => {
    return {
        isChangePlaceMode: false,
        isQuestionMode: false,
        isPlanningMode: false,
        isHistoryMode: false,
        isCompareMode: false,

        currentMovementAction: TABLE_MOVEMENT_ACTION_REPLACE,
        slotsWithToolBar: undefined,
        slotsCopyBuffer: undefined,

        isNearestPlace: false,
        questionColor: '',
        questionDate: null,
        questionDuration: 0,

        holidays: [],
        special : [],
        
        testEvent: null,
        workStart: null,
        workEnd: null,

        // if any slot is not in the work time
        supportedSlots: [], // index : needed_hour
        supportedWorkHours: [],
        slotToCopy: null,
        ceilSize: {
            height: 54,
            defaultWidth: 0,
            selectedWidth: 0,
        },
    }
}

export default {
    state: getDefaultState,
    modules: {
        header,
        slots,
        templates,
    },
    mutations: {
        'update-table-mode' : (state, payload) => {
            let {isPlanning, isCompare, isQuestion, isChangePlace, isHistoryMode } = payload

            if (isPlanning !== undefined){
                state.isPlanningMode = isPlanning
            }
            
            if (isCompare !== undefined){
                state.isCompareMode = isCompare
            }

            if (isHistoryMode !== undefined){
                state.isHistoryMode = isHistoryMode
            }

            if (isQuestion !== undefined){
                state.isQuestionMode = isQuestion
            }

            if (isChangePlace !== undefined){
                state.isChangePlaceMode = isChangePlace
            }
        },

        'delete-support-slot' : (state, {index}) => {
            state.supportedSlots = state.supportedSlots.filter((elem) => elem.index != index)
        },

        'update-support-work-time' : (state, {hours, slot}) => {
            if (slot){
                let isExists = state.supportedSlots.find(s => s.index == slot.index)
                if (!isExists) {
                    state.supportedSlots.push(slot)
                }
            }
            state.supportedWorkHours = hours
        },

        'update-table-movement-action': (state, payload) => {
            state.currentMovementAction = payload.action
        },

        'update-work-date' : (state, payload) => {
            let {special, holidays} = payload
            state.special = special
            state.holidays = holidays
        },
        'update-question-column' : (state, payload) => {
            let {color, date, duration, isNearestPlace} = payload
            
            state.isNearestPlace = isNearestPlace ?? false
            state.questionColor = color || ''
            state.questionDate = date
            state.questionDuration = duration ?? 0 
            state.isQuestionMode = date !== null
        },
        'update-work-time' : (state, payload) => {
            let {start, end} = payload

            state.workStart = start
            state.workEnd = end

        },
        'update-ceil-size' : (state, {columnCount}) => {
            state.ceilSize.defaultWidth = getColumnWidth(columnCount, false)
            state.ceilSize.selectedWidth = getColumnWidth(columnCount, true)
        },
        'update-slot-toolbar' : (state, payload) => {
            if (!payload){
                state.slotsWithToolBar = undefined
                return
            }

            if (!state.slotsWithToolBar){
                if (window.navigator.vibrate)
				    window.navigator.vibrate(65)
                state.slotsWithToolBar = []
            }
            let isExists = Boolean(state.slotsWithToolBar.find((slot) => {
                return payload.slot.index == slot.index
            }))
            if (!isExists) {
                state.slotsWithToolBar.push(payload.slot)
            } else {
                state.slotsWithToolBar = state.slotsWithToolBar
                    .filter((slot) => slot.index != payload.slot.index)
            }
        },

        'update-slot-copy-buffer' : (state, payload) => {
            state.slotsCopyBuffer = payload
        },

        'set-test-event' : (state, event) => {
            state.testEvent = event
        },

        'add-slot-toolbar-date' : (state, payload) => {
            if (!payload.slots || payload.slots.length == 0) return
            if (!state.slotsWithToolBar){
                state.slotsWithToolBar = []
            }

            let slots = payload.slots

            slots.forEach(slot => {
                let isExists = state.slotsWithToolBar
                    .find((slotCheck) => {
                        return slotCheck.index == slot.index
                    })
                if (!isExists) {
                    state.slotsWithToolBar.push(slot)   
                }
            });
        },
        'set-slot-to-copy' : (state, slot) => {
            state.slotToCopy = slot
        },
        'del-slot-toolbar-date' : (state, {date}) => {
            state.slotsWithToolBar = state.slotsWithToolBar
                .filter((slot) => !isEqualDate(slot.period.start.date, date))
        },

        'clear-table': (state, _) => {
            let ceilSize = state.ceilSize
            Object.assign(state, getDefaultState())
            state.ceilSize = ceilSize
        },

    },
    getters: {
        tableCreateEvent(state){
            return state.testEvent
        },
        tableMode(state){
            return {
                isChangePlace: state.isChangePlaceMode,
                isChangeColumn : state.isQuestionMode,
                isHistoryMode: state.isHistoryMode,
                isQuestion : state.isQuestionMode,
                isPlanning : state.isPlanningMode,
                isCompare : state.isCompareMode,
            }
        },
        tableDateToOpenTools(state){
            return state.columnDateToOpenTools
        },
        tableCeilSize(state){
            return state.ceilSize
        },
        tableSlotToCopy(state){
            return state.slotToCopy
        },
        tableSlotMovementAction(state){
            return state.currentMovementAction
        },

        tableSlotsToolBar(state){
            return state.slotsWithToolBar
        },  

        tableSlotsCopyBuffer(state){
            return state.slotsCopyBuffer
        },

        tableQuestionPlace(state){
            return {
                isNearestPlace : state.isNearestPlace,
                duration: state.questionDuration,
                color: state.questionColor,
                date: state.questionDate,
            }
        },
        tableCheckQuestionColumn: (state) => (day) => {
            if (state.questionDate == null) return false
            return state.questionDate.getDate() == day
        },

        calendarWorkStart(state){
            return state.workStart
        },

        calendarWorkEnd(state){
            return state.workEnd
        },

        calendarWorkHours(state){
            if (!state.workStart || !state.workEnd)
                return []

            let startHour = state.workStart.hour
            let endHour = state.workEnd.hour - 1

            let workHours = []
            for (let hour = startHour; hour <= endHour; hour++) {
                workHours.push(hour)
            }
            return workHours
        },

        calendarSupportedHours(state){
            return state.supportedWorkHours
        },

        calendarSupportedSlots(state){
            return state.supportedSlots
        },


        isHoliday: (state) => (date) => {
			if (typeof date == 'number') {
				return state.holidays.includes(date)
			}

            return state.holidays.includes(date.getDay())
        },
        isSpecialDay: (state) => (day) => {
			if (typeof day == 'number')
				return state.special.includes(day)
            let date = day.getDate()
            let month = day.getMonth()
            let specialDate = state.special
				.find(specialDate => specialDate.d == date && specialDate.m == month)

            return specialDate !== undefined
        },
    },
    actions: {
        'set-test-event' : (context, event) => context.commit('set-test-event', event), 
        'set-support-work-time' : setSupportWorkTime,
        'update-support-work-time' : updSupportWorkTime,

        'set-current-week' : (context, payload) => context.commit('set-current-week', payload),
        "set-slot-to-copy" : (context, payload) => context.commit('set-slot-to-copy', payload),
        'update-ceil-size' : (context, _) => context.commit('update-ceil-size', _),
        'update-work-date' : (context, payload) => context.commit('update-work-date',payload),
        'update-work-time' : (context, payload) => context.commit('update-work-time',payload),
        'update-table-mode' : (context, payload) => context.commit('update-table-mode', payload),
        'update-question-column' : (context, payload) => context.commit('update-question-column', payload),
        'update-slot-toolbar' : (context, payload) => context.commit('update-slot-toolbar', payload),
        'update-slot-toolbar-date': dateSlotsToToolbar,
        'update-table-movement-action' : (context, payload) => context.commit('update-table-movement-action', payload),
        'update-slot-copy-buffer' : (context, payload) => context.commit('update-slot-copy-buffer', payload),

        'clear-table' : ({commit,dispatch}, _) => {
            commit('clear-table')
            dispatch('clear-slots')
        },
    }
}