import axios from 'axios';
import client, {errorView} from './client.js';
import TokenService from './token-service.js';
import CMS from './cms/service.js';
import router from '../router.js';

const auth = axios.create({
    baseURL: "/aaa/api/v1/auth",
})

class AuthService {

    static signIn(userData) {
        return auth.post('', userData)
            .then((response) => {
                if (response.data.err)
                    throw response.data.err
                let data = response.data
                let tokenPair = {
                    refreshToken: data.refreshToken,
                    accessToken: data.accessToken
                }

                TokenService.set(tokenPair)
                return response
            })
    }

    static signOut() {
        client.post('/aaa/api/v1/auth/log-out', {
                refreshToken: TokenService.getRefresh()
            })
            .then(() => {
                TokenService.del()
                router.push({ name: 'home' })
            })
    }

    static getOauthData(authMethod){
        return client.post('/aaa/api/v1/auth/oauth2-url', {authMethod})
            .then((response) => response.data)
            .catch(err => errorView("Get oauth data", err))
    }
}



export default AuthService;