import axios from 'axios'
import router from '../router.js'

import Alert from '../scripts/alert.js';
import TokenService from './token-service.js'

import uuid4 from '../scripts/uuid.js';

const client = axios.create({
    baseURL: "",
    headers: {
        "X-Hg-Request-Id": uuid4(),
    }
});

client.interceptors.request.use(
    (request) => {
        let access = TokenService.getAccess()
        if (access)
            request.headers.Authorization = access

        return request
    },
    (error) => Promise.reject(error)
)

let isRefreshing = false;
let isRefreshTried = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach((prom) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

client.interceptors.response.use(
    (response) => {
        console.info("response interceptor: OK : ", response.config.url)
        return response
    },
    (error) => {

        const originalRequest = error.config;
        let statusCode = error.response.status

        if (statusCode === 401 && !originalRequest._retry) {

            console.log("response interceptor: 401 : ", error.config.url);

            if (isRefreshing) {
                console.log("is refreshing");
                return new Promise((resolve, reject) => {
                        failedQueue.push({ resolve, reject });
                    })
                    .then((accessToken) => {
                        originalRequest.headers.Authorization = accessToken
                        return axios(originalRequest);

                    })
                    .catch((err) => {
                        console.log("promise reject");
                        return Promise.reject(err);
                    });
            }

            originalRequest._retry = true;
            isRefreshing = true;

            const refreshToken = TokenService.getRefresh()
           
            return new Promise(function(resolve, reject) {
                axios
                    .post("/aaa/api/v1/auth/tokens/refresh", { refreshToken })
                    .then((response) => {
                        let tokenPair = response.data
                        TokenService.set(tokenPair)
                        console.log(`refresh token : ${refreshToken} 
                            new token pair:`, tokenPair);
                        originalRequest.headers.Authorization = tokenPair.accessToken
                        processQueue(null, tokenPair.accessToken);
                        resolve(axios(originalRequest));
                    })
                    .catch((err) => {
                        isRefreshTried = true;
                        processQueue(err, null);
                        reject(err);
                        if (TokenService.tokenIsExists())
                            TokenService.del()
                        if (router.currentRoute.path != "/auth") {
                            router.push("/auth");
                        }
                    })
                    .finally(() => {
                        console.error("interceptor finally");
                        isRefreshing = false;
                    });
            });
        }

        return Promise.reject(error);
    }
)

function errorView(messageTemplate, err){
    let result = {err}
    let message = `${messageTemplate} : ${err.message}`

    let isHttpError = err.response !== undefined
    if (isHttpError){
        if (!isRefreshTried && err.response.status !== 401 && err.response.status !== 403){
            Alert.fire(message)
        }
        result['status'] = err.response.status
    } 
    else {
        Alert.fire(message)
    }

    return result 
}

export {errorView}
export default client