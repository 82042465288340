import client from "../../client";
import {errorView} from "../../client"

class Rating {
	static create(calendarUid, data) {
		return client.post(`/cms/api/v1/calendars/${calendarUid}/slot-ratings`, data)
			.then((response) => response.data)
			.catch(err => errorView("Create calendar rating", err))
	}

	static update(calendarUid, ratingId, data) {
		return client.put(`/cms/api/v1/calendars/${calendarUid}/slot-ratings/${ratingId}`, data)
			.then((response) => response.data)
			.catch(err => errorView("Create calendar rating", err))
	}
	
	static delete(calendarUid, ratingId) {
		return client.delete(`/cms/api/v1/calendars/${calendarUid}/slot-ratings/${ratingId}`)
			.then((response) => response.data)
			.catch(err => errorView("Delete calendar rating", err))
	}

	static setTypeName(calendarUid, data) {
		return client.post(`/cms/api/v1/calendars/${calendarUid}/slot-ratings/set-type-name`, data)
			.then((response) => response.data)
			.catch(err => errorView("Set calendar rating type name", err))
	}
}

export default Rating