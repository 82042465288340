import { cardPosition } from "./cardPosition";
import TimePeriod from "./timePeriod";

const defaultState = () => {
    return {
        isBusy: false,
        nearest: {},
        insertIndex: undefined,
    }
}

class Slot {
    
    /**
     * 
     * @param {String} start - slot start
     * @param {String} duration - slot duration
     * @param {Object} args - other optional params for slot
     */
    constructor(start, duration, args) {

        // main params
        let startDate = new Date(start)
        let index = args.index || args.id

        this.period = new TimePeriod(startDate, index, duration)
        this.index = index
        this.title = args.title || ""
        this.settings = {}
        this.cardPeriod = this.period.copy()
        this.state = defaultState()
        this.styles = {
            isChangeColumn: false,
            isLastPressed: false,
        }

        this.compare = {
            animate: false,
            isActive: false,
            items: [],
        }
        this.copySlotIndex = 0
        this.attenders = []

        // other params
        Object.keys(args).forEach(param => {
            this.settings[param] = args[param]
        })
    }

    date() {
        return this.period.date()
    }
    month() {
        return this.period.month()
    }
    year() {
        return this.period.year()
    }
    
    update(start, duration, args){

        // main params
        let startDate = new Date(start)
        let index = args.index || args.id

        this.period = new TimePeriod(startDate, index, duration)
        this.index = index
        this.title = args.title || ""
        this.settings = {}

        // other params
        Object.keys(args).forEach(param => {
            this.settings[param] = args[param]
        })
    }

    updateBusyness({isBusy, nearestDate}){

        if(!isBusy){
            this.state.isBusy = false
            this.state.nearest = {}
            this.state.insertIndex = undefined
            return
        }

        this.state.isBusy = isBusy
        this.state.nearest = nearestDate
    }

    updateIntersection(slotStart, intersection){
        if(!intersection) return

        let {newSlots, oldSlots} = intersection
        
        slotStart = slotStart.getTime()

        let interSlot = Object.values(newSlots).find( intersectionSlot => {
            
            let intersectionSlotPeriod = intersectionSlot.period.projection(this.period.start.date) 
            let intersectionSlotStart = intersectionSlotPeriod.start.time

            let difference = Math.abs(slotStart - intersectionSlotStart)
            difference = difference / 1000 / 60

            return difference < 15
        })

        if (!interSlot){
            this.state.insertIndex = undefined
            return
        }

        this.state.insertIndex = interSlot.index
    }

    /**
     * Return copy of this slot
     */
    makeCopy(index=null) {
        let start = new Date(this.period.start.time)
        let newSlot = new Slot(start, this.period.duration, {
            ...this.settings,
        })
        if (index) {
            newSlot.index = index
            newSlot.settings.id = index
            newSlot.period.index = index
            newSlot.cardPeriod.index = index
        }
        return newSlot
    }

    updateSettings(obj){

        if (obj.title){
            this.title = obj.title
            delete obj['title']
        }
        
        if (obj.dur){
            this.period.duration = obj.dur
            this.cardPeriod.duration = obj.dur
            delete obj["dur"]            
        }
            
        if (obj.startAt){
            this.period.update(obj.startAt)
            this.cardPeriod.update(new Date(obj.startAt.getTime()))
            delete obj["startAt"]
        }


        Object.keys(obj).forEach(param => {
            this.settings[param] = obj[param]
        })
    }

    currentTime() {
        let hour = this.period.hour()

        let minute = this.period.minute()
        if (minute < 10) minute = "0" + minute

        return `${hour}:${minute}`
    }

    /**
     * Return slot is past 
     */
    isPast() {
        return this.period.isPast()
    }

    /**
     * Return slot position in column
     */
    getPosition(workStart, tableItemHeight) {
        let startDate = this.period.start.date

        return cardPosition(startDate, workStart, tableItemHeight)
    }

    setCompareWithAnimation(isCompare){
        if (this.compare.isActive && !isCompare){
            this.compare.animate = false
            setTimeout(() => this.compare.isActive = false, 400)            
        }

        if (!this.compare.isActive && isCompare) {
            this.compare.animate = true
            setTimeout(() => this.compare.isActive = true, 400)
        }
    }

    clearState(){
        this.state = defaultState()
    }
    /**
     * Add attenders to the slot
     * @param {Array} attender 
     */
    addAttenders(attenders){
        if (!attenders || attenders.length == 0)
            return

        attenders.forEach(attender => {
            let isAttended = this.attenders
                .find(user => attender.userId == user.userId)
            if (!isAttended)
                this.attenders.push(attender)
        })
    }

        /**
     * Remove attender from the slot
     * @param {Array} attender 
     */
    removeAttender(userId){
        this.attenders = this.attenders.filter((attender) => {
            return attender.userId != userId
        })
    }
    

    toSubmitData(isProCalendar=false){  
        
        let submitData = {
            startAt:	this.period.start.date,
            dur:		this.period.duration,
            timezoneId:	this.settings["timezoneId"],
        }

        if (this.settings["title"] && this.settings["title"].length > 0)
            submitData["title"] = this.settings["title"]
        if (this.settings["description"] && this.settings["description"].length > 0)
            submitData["description"] = this.settings["description"]
        if (this.settings["maxAttenders"] && this.settings["maxAttenders"] != 0)
            submitData["maxAttenders"] = this.settings["maxAttenders"]
        
        if (this.settings["hostId"])
            submitData["hostId"] = this.settings["hostId"] 
        
        if (this.settings["cellTextColor"])
            submitData["cellTextColor"] = this.settings["cellTextColor"]
        if (this.settings["cellBackgroundColor"])
            submitData["cellBackgroundColor"] = this.settings["cellBackgroundColor"]

		if (isProCalendar) {
			const proSettings = [
				'levelId',
				'ratingId',
				'avatarUtl',
				'wallpapers',
				'isWaitingListEnabled',
				'maxAttenders',
				'fixedAttenders'
			]

			submitData["pro"] = {}
			proSettings.forEach(settingName => {
				if (this.settings[settingName]) {
					submitData["pro"][settingName] = this.settings[settingName]
				}
			})
		} else {
			const regularSettings = [
				'attenders',
				'chargingRule'
			]

			submitData["regular"] = {}
			regularSettings.forEach(settingName => {
				if (this.settings[settingName]) {
					submitData["regular"][settingName] = this.settings[settingName]
				}
			})
		}
        return submitData
    }
}

class UpdateSlotTime {
    /**
     * 
     * @param {Slot} slot 
     * @param {Date} date 
     */
    constructor(slot, date){
        this.slot = slot
        this.date = date
    }
}

export default Slot

export {
    UpdateSlotTime,
}