import client, { errorView } from "../../client"


class Template{
    static getAll(uid) {
        return client.get(`/cms/api/v1/calendars/${uid}/available-slot-templates`)
        .then((response) => response.data)
        .catch(err => errorView("Get calendar templates ", err))
    }
}

export default Template