import axios from "axios";

import uuid4 from "../scripts/uuid.js";

const token = axios.create({
    baseURL: "/aaa/api/v1/auth/tokens",
    headers: {
        "X-Hg-Request-Id": uuid4(),
    }
})

class TokenService {
    static isValid() {

        let response = token.post('/validate', {}, {
            headers: {
                "Authorization": this.getAccess(),
            }
        }).then((response) => { return response })

        console.log('responce', response)
        let result = response.status == 200

        return result
    }

    static refreshToken() {
        token.post('/refresh')
            .then((response) => {
                let data = response.data
                let tokenPair = {
                    refreshToken: data.refreshToken,
                    accessToken: data.accessToken
                }

                this.set(tokenPair)
            })
            .catch(_ => {
                this.del()
            });
    }

    static set(tokenPair) {
        localStorage.setItem("horaToken", JSON.stringify(tokenPair));
    }

    static del() {
        localStorage.removeItem("horaToken");
    }

    static tokenIsExists() {
        let access = this.getAccess()
        return Boolean(access)
    }

    static getAccess() {
        var jwtToken = localStorage.getItem("horaToken")
        if (!jwtToken)
            return null

        let result = JSON.parse(jwtToken)

        return result.accessToken
    }
    static getRefresh() {
        var jwtToken = localStorage.getItem("horaToken")
        if (!jwtToken)
            return null

        let result = JSON.parse(jwtToken)

        return result.refreshToken
    }
}



export default TokenService