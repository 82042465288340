const NONE_MEMBERSHIP_LEVEL = 0
const FOLLOWER_MEMBERSHIP_LEVEL = 1
const MANAGER_MEMBERSHIP_LEVEL = 2
const ADMIN_MEMBERSHIP_LEVEL = 4
const OWNER_MEMBERSHIP_LEVEL = 8

export {
	NONE_MEMBERSHIP_LEVEL,
	FOLLOWER_MEMBERSHIP_LEVEL,
	MANAGER_MEMBERSHIP_LEVEL,
	ADMIN_MEMBERSHIP_LEVEL, 
	OWNER_MEMBERSHIP_LEVEL,
}