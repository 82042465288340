<template>
	<div id="app">
		<span v-if="isMainLayout">
			<MainLayout>
				<router-view></router-view>
			</MainLayout>
		</span>
		<span v-else>
			<router-view></router-view>
		</span>

	</div>
</template>

<script>
import TokenService from './service/token-service.js'
import UTM from './service/utm-service.js'

import MainLayout from './pages/MainLayout.vue'
import { cssVw, refreshCssUnits } from './scripts/cssUnits.js'
import ModalProvider from './scripts/modalProvider.js'

export default {
	name: 'App',
	components: {
		MainLayout
	},
	created() {
		window.addEventListener('popstate', this.popstateHandler)
		window.addEventListener('resize', this.resizeHandler)
	},
	
	beforeDestroy() {
		window.removeEventListener('popstate', this.popstateHandler)
		window.removeEventListener('resize', this.resizeHandler)
	},

	beforeMount() {
		// Save utm attributes from route
		UTM.save(this.$route.query)
	},
	computed: {
		currentRouteName() {
			return this.$route.name
		}
	},
	data() {
		return {
			isMainLayout: true,
		}
	},
	watch: {
		$route(to, from) {
			this.isMainLayout = this.isNavPage(to.name)
			let routeName = this.$route.name
			// First routed render
			if (from.name == null && TokenService.tokenIsExists() && !this.isTestPage(routeName)) {
				this.$store.dispatch("update-user-data")
					.then(() => this.$store.dispatch("setup-geo"))
			}
			if (from.name != to.name) {
				this.clearOpenedModals()
			}
		},
	},
	methods: {
		isTestPage(name) {
			// Test page
			return name.includes("test")
		},
		isSettingsPage(name) {
			return name.includes("settings")
		},
		isNavPage(name) {
			let routeName = name || this.$route.name

			const blackList = [
				'auth',
				'create-calendar',
				'calendar-settings',
				'welcome',
				'slot-attend',
				'slot-edit',
			]

			if (this.isTestPage(routeName)) {
				return false
			}

			if (this.isSettingsPage(routeName)) {
				return false
			}

			if (blackList.includes(routeName)) {
				return false
			}

			return true
		},
		clearOpenedModals(){
			ModalProvider.openedModals = []
		},

		popstateHandler() {
			ModalProvider.closeLastModal()
		},
		resizeHandler() {
			refreshCssUnits()
			let tableHeader = this.$store.getters.tableHeader
			if (!tableHeader)
				return
			let columnCount = tableHeader.columnCount
			this.$store.dispatch('update-ceil-size', {columnCount})
		}
	},
}
</script>

<style>

#air-datepicker-global-container {
    z-index: 1000;
}

#app {
	height: 100%;
}

a:visited {
	color: #4773e5;
}

* {
	padding: 0px;
	margin: 0px;
}

html {
	margin: 0px;
	background: white;
	font-family: 'Lato', sans-serif;
}

body {
	margin: 0px;
	overflow: auto;
	overscroll-behavior-y: contain;
}

#studio {
	padding-right: 4px;
}

.fade-enter-active {
	animation: fade .5s;
}

.fade-leave-active {
	animation: fade .5s reverse;
}

@keyframes fade {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

.toast {
	border-radius: 5px;
	opacity: 0;
}
</style>
