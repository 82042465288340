import { cssVw } from './cssUnits'

// const sizes = {
// 	1 : {
// 		default: 0,
// 		selected: 100,
// 	},
//     3 : {
//         default: 25,
//         selected: 50,
//     },
//     5 : {
//         default: 15.75, // 15.75 * 4 = 63
//         selected: 37,
//     },
//     7 : {
//         default: 10.5,
//         selected: 37,
//     }
// }
const sizes = {
	1 : {
		default: 0,
		selected: 100,
	},
    3 : {
        default: 33.3,
        selected: 33.3,
    },
    5 : {
        default: 20, // 15.75 * 4 = 63
        selected: 20,
    },
    7 : {
        default: 14.28,
        selected: 14.28,
    }
}



function getColumnWidth(columnCount, isSelected, indent=34){

	const containerSize = cssVw * 100 - indent

    let type = isSelected ? "selected" : "default"
    let size = sizes[columnCount][type]

    let percent = size * containerSize / 100 

    if (isSelected) {
        console.log('isSelected :>> ',  Math.round(percent) );
    }

    return Math.round(percent) 
}

export {
    getColumnWidth
}