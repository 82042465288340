<template>
    <div class="menu-item" @click="usage">
        <div class="icon-cont">
            <span class="iconify"
                :style="`color: ${iconColor};`"
                :data-icon="icon"
                :data-width="iconSize.width" 
                :data-height="iconSize.height">
            </span>
        </div>
        <div class="title" :style="`color: ${titleColor}; ${titleStyle}`">
            {{title}}
        </div>
    </div>
</template>

<script>
export default {
    name: 'BurgerMenuItem',
    props: {
        title: {
            type: String,
            required: true,
        },
        titleStyle: {
            type: String,
            default: ''
        },
        titleColor: {
            type: String,
            default: 'black',
        },
        icon: {
            type: String,
            default: ''
        },
        iconSize: {
            type: Object,
            default() {
                return {
                    height: 20,
                    width: 20
                }
            },
        },
        iconColor: {
            type: String,
            default: 'black'
        },
        usage: {
            type: Function,
            default: () => console.log('burger menu item!'),
        },
    },
};
</script>

<style scoped>
.menu-item{
    padding: 5px 11px;
    display: grid;
    grid-template-columns: 20px calc(100% - 20px - 10px);
    grid-column-gap: 10px;
        -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    user-select: none;
}
.icon-cont{
    height: 20px;
}
.title{
    display: flex;
    align-items:center;
    font-size: 15px;
}
</style>