<template>
    <div class="personal-calendar-list">
        <BurgerMenu :show="show" :closer="closer" name="personal" :height="isWithSearch? 360 : 260">

            <!-- Title -->

            <div class="title-container" v-if="title">
                {{title}}
            </div>

            <!-- Search field -->

            <div class="search-container" v-if="isWithSearch">
				<b-input v-model="search" 
					placeholder="Search..." 
					icon="magnify"
					icon-right="close"
					icon-right-clickable
					@icon-right-click="search = ''"
					expanded>
				</b-input>
            </div>

            <!-- Calendar list -->

            <div class="calendar-list">
                <Item v-for="calendar in getItemsBySearch()" 
                    :key="'second-menu-item-' + calendar.uid"
                    :title="calendar.title"
                    :usage="() => selectCalendar(calendar.uid)"
                    :description="calendar.description"
                    :isSelected="isSelected(calendar.uid)"
                    :isDefault="calendar.isDefault"
                />
                <Item v-if="!excludeCreate"
                    title="Create calendar"
                    icon="uil:plus"
                    :usage="createCalendar"
                />
            </div>
        </BurgerMenu>
    </div>

</template>

<script>
import router from '../../router'
import Item from './Item.vue'

import BurgerMenu from '../burger-menu/BurgerMenu.vue'

export default {
    name: 'PersonalCalendarList',
    components: {
        BurgerMenu,
        Item,
    },
    
    props: {
        show: Boolean,
        title: String,
        selector: Function,
        closer: Function,
        excludeCurrent: Boolean,
        excludeCreate: Boolean,
    },
    
    data(){
        return{
            search: "",
        }
    },

	computed: {
		calendars(){
			return this.$store.state.user.calendars.managed
		},
		isWithSearch(){
			const calendarCount = Object.values(this.calendars).length
			return calendarCount > 10
		}
	},

    methods: {
        createCalendar(){
            router.push({name:"create-calendar"})
        },
        isSelected(uid){
            let currentCalendarUid = this.$route.params.calendarUid
            return currentCalendarUid == uid
        },
        selectCalendar(calendarUid){
            this.closer()
            if (this.selector){
                return this.selector(calendarUid)
            }
			if (this.$route.name == "calendar-schedule") {
				router.push({name: 'calendar-schedule', params: {calendarUid: calendarUid}, query: this.$route.query})
				return 
			}
            router.push({name: 'calendar-schedule', params: {calendarUid: calendarUid}, query: this.$route.query})
        },
        getItemsBySearch(){
            let result = []
            let managedCalendars = this.calendars
            let managedCalendarsUid = Object.keys(managedCalendars)
            
            // Setup current (private) calendar to first position
            let currentCalendarUid = this.$route.params.calendarUid
            let currentCalendarIndex

            managedCalendarsUid.forEach( (uid, index) => {
                result.push(managedCalendars[uid])
                if (currentCalendarUid == uid){
                    currentCalendarIndex = index
                }
            })
            // If current calendar in list of managed calendars
            if (currentCalendarIndex){
                let oldFirstItem = result[0]
                result[0] = result[currentCalendarIndex]
                result[currentCalendarIndex] = oldFirstItem
            }


            if (this.excludeCurrent) {
                result = result.filter(calendar => calendar.uid != currentCalendarUid)
            }

            if (this.search == "") return result

            result = result.filter( calendar => {
                return calendar.title.toLowerCase().includes(this.search.toLowerCase())
            })

            return result
        },
        clearSearch(){
            this.search = ""
        },
        updateSearch(event){
            this.search = event.target.value
        },
    },

}
</script>

<style scoped>

.title-container {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
}

.search-container {
	margin: 2px 12px;
}
.calendar-list{
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 16px;
	flex: 1;
	padding-bottom: 16px;
}

.calendar-list::-webkit-scrollbar {
  display: none;
}
 

.calendar-list {
  -ms-overflow-style: none;  /* IE и Edge */
  scrollbar-width: none;  /* Firefox */
}

.err__cont{
    display: flex;
    justify-content: center;
    align-items: center;
    color: grey;
    height: 140px;
}
</style>