import client, { errorView } from "../../client";

const DOCS_NAMES = [
	'terms',
]

class Docs {
	static get(name) {
		return client.get(`/aaa/api/v1/documents/${name}`)
		.then((response) => response.data)
		.catch(err => errorView(`Get document '${name}'`, err))
	}
}

export default Docs