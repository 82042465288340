import YYYYMMDD from "../../../scripts/date"
import client, { errorView } from "../../client"

class Slots{
    
    static get(uid, slotId) {
        return client.get(`/cms/api/v1/calendars/${uid}/slots/${slotId}`).then((response) => response.data)
        .catch(err => errorView("Get slot ", err))
    }

    static getAll(uid, from, to) {
        let formatFrom = YYYYMMDD(from)
        let formatTo = YYYYMMDD(to)
        let params = `from=${formatFrom}&to=${formatTo}`

        return client.get(`/cms/api/v1/calendars/${uid}/slots?${params}`).then((response) => response.data)
        .catch(err => {
            if (err.response.status == 403)
                return (err);
            errorView("Get slot list ", err)
        })
    }

    /**
     * Update slot time 
     */
    static patch(calendarUid, slotId, data){
        return client.patch(`/cms/api/v1/calendars/${calendarUid}/slots/${slotId}`, data)
            .then((response) => response.data)
            .catch(err => errorView("Update slot time ", err))
    }
    
    static multiPatch(calendarUid, data){
        return client.patch(`/cms/api/v1/calendars/${calendarUid}/slots`, data)
            .then((response) => response.data)
            .catch(err => errorView("Update multi slot time ", err))
    }
    
    static update(calendarUid, slotId, data) {
        return client.put(`/cms/api/v1/calendars/${calendarUid}/slots/${slotId}`, data).then((response) => response.data)
        .catch(err => errorView("Update slot info ", err))
    }

    static upload(uid, slots) {
        let submitData = []
        if (slots.length){ 
            submitData = slots
        } else {
            submitData.push(slots)
        }

        return client.post(`/cms/api/v1/calendars/${uid}/slots`, {
            items: submitData,
        }).then((response) => response.data.items)
        .catch(err => errorView("Create slot ", err))
    }

    static delete(calendarUid, slotId){
        // TODO : add request execution 
        return client.delete(`/cms/api/v1/calendars/${calendarUid}/slots/${slotId}`)
            .then((response) => response.data)
            .catch(err => errorView("Delete slot ", err))
    }

    static attend(calendarUid, slotId){
        return client.post(`/cms/api/v1/calendars/${calendarUid}/slots/${slotId}/attend`, {})
            .then((response) => response.data)
            .catch(err => errorView("Attend to slot ", err))
    }

    static unattend(slotId){
        return client.post(`/cms/api/v1/slots/${slotId}/cancel`, {})
            .then((response) => response.data)
            .catch(err => errorView("Attend to slot ", err))
    }

    static cloneByColumns(calendarUid, from, days, to){
        return client.post(`/cms/api/v1/calendars/${calendarUid}/slots/clone`, {
            days, 
            from: YYYYMMDD(from), 
            to: to.map(date => YYYYMMDD(date)),
        }).then((response) => response.data)
        .catch(err => errorView("Clone week ", err))
    }
}

export default Slots