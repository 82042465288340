// cardPosition return position of training card in table by time
function cardPosition(time, workStart, tableItemHeight) {
    if (!time) {
        return NaN
    }

    let hour = time.getDate ? time.getHours() : time["hour"]
    let minute = time.getDate ? time.getMinutes() : time["minute"]

    let position = hour + (minute / 60) - workStart.hour - (workStart.minute / 60)

    return position * tableItemHeight
}

export { cardPosition }