class Timezone {
	id
	name
	utcOffset
	constructor({id, name, utcOffset}) {
		this.id = id
		this.name = name
		this.utcOffset = utcOffset
	}
}

export default Timezone