<template>
	<div class="modal-template" v-bind:class="{
		disabled: !show,
	}">
		<transition name="backdrop-animation">
			<div class="backdrop" v-if="show"></div>
		</transition>
		<!-- <transition :name="transition" appear> -->
		<transition :name="transition">
			<div class="content" v-if="show" ref="content" v-bind:class="{active: show}" @click="close">
				<slot></slot>
			</div>
		</transition>
	</div>
</template>

<script>
import ModalProvider from "../../scripts/modalProvider.js"

export default {
	name: 'ModalTemplate',

	model: {
		prop: 'show',
		event: 'update:show',
	},

	props: {
		show: {
			type: Boolean,
			required: true,
		},
		name: {
			type: String,
			required: true,
		},
		closer: {
			type: Function,
			required: true,
		},
		transition: {
			type: String,
			default: "modal-fade",
		},
		closeOnBackdrop: {
			type: Boolean,
			default: true,
		},
		queryName: {
			type: String,
		},
		queryValue: {
			type: String,
			default: "1",
		},
	},

	data() {
		return {
			isEnterAnimation: false,
			isLeaveAnimation: false,
		}
	},

	mounted() {
		ModalProvider.addModal(this.name, this.openHandler, this.closeHandler)
	},
	
	updated() {
		ModalProvider.addModal(this.name, this.openHandler, this.closeHandler)
	},

	computed: {
		isShow: {
			get(){
				return this.show
			},
			set(value){
				this.$emit('update:show', value)
			},
		}
	},

	watch: {
		show(value){
			if (value) {
				ModalProvider.openModal(this.name)
			} else {
				ModalProvider.closeModal(this.name)
			}
		}
	},

	methods: {
		openHandler(){
			if (!this.queryName) 
				return

			let query = {...this.$route.query}
			query[this.queryName] = this.queryValue
			this.$router.push({
				name: this.$route.name,
				params: this.$route.params,
				query: query
			}).catch(_ =>{})
		},
		close(ev){
			if (!this.show || !this.closeOnBackdrop)
				return

			if (ev.target === this.$refs["content"])
				this.$router.back()
				// ModalProvider.closeModal(this.name)
		},
		closeHandler(){
			this.closer()
			if (!this.queryName) 
				return
			let query = {...this.$route.query}
			delete query[this.queryName]
			this.$router.replace({
				name: this.$route.name,
				params: this.$route.params,
				query: query
			}).catch(_ =>{})
		},
	},
};
</script>

<style scoped>

.modal-template > .backdrop{
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1000;
	background-color: rgba(0, 0, 0, 0.5);
}

.modal-template > .content {
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}
.modal-template > .content.active {
	z-index: 1000;
}

.modal-template > .content > *{
	position: absolute;
	z-index: 1001;
}

.backdrop-animation-enter-active{
	animation: enter-shadow 0.2s ease-in;
}

.backdrop-animation-leave-active{
	animation: enter-shadow 0.2s ease-out reverse;
}


.modal-template.enter{
	animation: .2s enter-shadow ease-in;
}

@keyframes enter-shadow {
	from {
		background-color: rgba(0, 0, 0, 0);
	}
	to {
		background-color: rgba(0, 0, 0, 0.5);
	}
}

.modal-fade-enter-active{
	animation: fade .2s;
}

.modal-fade-leave-active{
	animation: fade .2s reverse;
}

</style>