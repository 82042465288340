import client, { errorView } from "../../client"

class Attenders {
	static create(calendarUid, data){
		return client.post(`/cms/api/v1/calendars/${calendarUid}/attendees`, data)
			.then((response) => response.data)
			.catch(err => errorView("Create calendar attender", err))
	}
	static list(calendarUid){
		return client.get(`/cms/api/v1/calendars/${calendarUid}/attendees`)
			.then((response) => response.data)
			.catch(err => errorView("List calendar attenders", err))
	}
	// static update(calendarUid, priceId, data){
	// 	return client.put(`/cms/api/v1/calendars/${calendarUid}/slot-levels/${priceId}`, data)
	// 		.then((response) => response.data)
	// 		.catch(err => errorView("Update calendar price lvl", err))
	// }
	// static delete(calendarUid, priceId){
	// 	return client.delete(`/cms/api/v1/calendars/${calendarUid}/slot-levels/${priceId}`)
	// 		.then((response) => response.data)
	// 		.catch(err => errorView("Delete calendar price lvl", err))
	// }
}

export default Attenders