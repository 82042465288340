import client from "../../client"
import { errorView } from "../../client"

class Prices {
	static create(calendarUid, data){
		return client.post(`/cms/api/v1/calendars/${calendarUid}/slot-levels`, data)
			.then((response) => response.data)
			.catch(err => errorView("Create calendar price lvl", err))
	}
	static update(calendarUid, priceId, data){
		return client.put(`/cms/api/v1/calendars/${calendarUid}/slot-levels/${priceId}`, data)
			.then((response) => response.data)
			.catch(err => errorView("Update calendar price lvl", err))
	}
	static delete(calendarUid, priceId){
		return client.delete(`/cms/api/v1/calendars/${calendarUid}/slot-levels/${priceId}`)
			.then((response) => response.data)
			.catch(err => errorView("Delete calendar price lvl", err))
	}
}

export default Prices