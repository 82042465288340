import Vue from 'vue';
import Vuex from 'vuex';

import calendar from './modules/calendar-page-store'
import user from './modules/user-store'
import geo from './modules/geo-store'

Vue.use(Vuex);

const getDefaultState = () => {
    return {}
}

const store = new Vuex.Store({
    state: getDefaultState(),
    modules: {
        calendar,
        user,
        geo,
    },
});

export default store