import client, { errorView } from "../../client";

class Geo {
    static countryList() {
        return client.get('/cms/api/v1/countries', { headers: { 'Hg-Language': 'en' } })
        .then((response) => response.data)
        .catch(err => errorView("Get country list", err))
    }

    static timezoneList() {
        return client.get('/cms/api/v1/timezones', { headers: { 'Hg-Language': 'en' } })
        .then((response) => response.data)
        .catch(err => errorView("Get timezone list", err))
    }

    static workdayCalendar = class {
        static getByID(workdayCalendarId){
            return client.get(`/cms/api/v1/workday-calendars/${workdayCalendarId}`).then(response => response.data)
            .catch(err => errorView("Get work days", err))
        }

        static getByCountry(countryId){
            return client.get(`/cms/api/v1/workday-calendars?countryId=${countryId}`).then(response => response.data)
            .catch(err => errorView("Get work days", err))
        }
    }
    

}

export default Geo