<template>
	<ModalTemplate v-model="isShow" 
		:name="`burger-menu-${name}`" 
		:closer="closeHandler"
		:queryName="`m_bm-${name}`"
		transition="">
			<div class="modal-cont" ref="burger"
			v-bind:style="burgerStyles">
				<div class="selector-container" ref="burger-tool"
				@pointerdown="pointerDown"
				@pointermove="pointerMove"
				@pointerup="pointerUp">
					<div class="selector"></div>
				</div>
				<div class="title-container" v-if="title">
					{{ title }}
				</div>
				<div class="item-cont">
					<slot></slot>
				</div>
			</div>
	</ModalTemplate>
</template>

<script>
import BurgerMenuItem from './BurgerMenuItem.vue'
import ModalTemplate from '../modals/ModalTemplate.vue'
import { cssVh } from '../../scripts/cssUnits';
import ModalProvider from '../../scripts/modalProvider';

export default {
    name: 'BurgerMenu',
    components: {
		ModalTemplate, 
		BurgerMenuItem 
	},
    props: {
		name: {
			type: String,
			required: true,
		},
        show: {
			type: Boolean,
			required: true,
		},
        closer: {
			type: Function,
			required: true,
		},
		closeDifference: {
			type: Number,
			default: 160,
		},
		height: {
			type: Number,
			default: 260,
		},
        title: String,
    },
    data() {
        return {
			currentPositionBot: this.height * -1,
			isDragging: false,
			offsetY: 0,
			swipeStart: null,
        };
    },
    computed: {
        isShow: {
            get(){
                return this.show
            },
            set(_){
                this.closer()
            }
        },
		defaultPositionBot(){
			return 0
		},
		burgerStyles(){
			return {
				bottom: `${this.currentPositionBot}px`,
				height: `${this.height}px`,		
			}
		}
    },

	watch:{
		show(value){
			if (value)
				this.withTransition(0, 0.45)
		},
	},

    methods: {
		pointerDown(ev) {
			this.$refs["burger-tool"].setPointerCapture(ev.pointerId)
			
			this.isDragging = true
			const collapseToolRect = this.$refs["burger-tool"].getBoundingClientRect()
			this.offsetY = ev.clientY - collapseToolRect.top

			this.swipeStart = new Date()
		},
		pointerMove(ev) {
			let futureHeight = 100 * cssVh - (ev.clientY - this.offsetY) - this.height
			if (futureHeight > 0)
				return ev.preventDefault()
			if (this.isDragging) {
				this.currentPositionBot = futureHeight
			}
		},
		pointerUp(ev) {
			this.isDragging = false
			const difference = Math.abs(this.currentPositionBot - this.defaultPositionBot)
			if (difference >= this.closeDifference || this.isFastSwipe()){
				ModalProvider.back()
			} else {
				this.withTransition(this.defaultPositionBot, 0.2)
			}
			this.$refs["burger-tool"].releasePointerCapture(ev.pointerId)
		},
		isFastSwipe(){
			let now = new Date()
			return now.getTime() - this.swipeStart.getTime() <= 200
		},
		closeHandler(){
			let isFullAnimation = this.currentPositionBot == this.defaultPositionBot
			this.withTransition(this.height * -1, isFullAnimation ? 0.45 : 0.3)
			.then(this.closer)
		},
		withTransition(position, seconds, isClose=false){
			return new Promise((resolve, _) => {
				setTimeout(()=> {
					const burger = this.$el.querySelector(".modal-cont")
					if (!burger)
						return 
					burger.style.transition = `bottom ${seconds}s cubic-bezier(0.22, 0.61, 0.36, 1)`
					this.currentPositionBot = position
					setTimeout(() => {
						this.$refs["burger"].style.transition = ""
						resolve()
					}, seconds*1000)
				}, 0)
			})
		}
    },
}
</script>

<style scoped>
.modal-cont{
    position:absolute;
    left: 0;
	right: 0;
	bottom: -80%;
    width: 100%;
    background: white;
    border-radius: 10px 10px 0 0;

	display: flex;
	flex-direction: column;
	/* transition: bottom .3s cubic-bezier(0.22, 0.61, 0.36, 1); */
}

.title-container {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
	position: sticky;
}

/* .burger-animation-enter-active{
	animation: burger .6s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.burger-animation-leave-active{
	animation: burger 60s cubic-bezier(0.22, 0.61, 0.36, 1) reverse;
}

@keyframes burger {
    from {
        bottom: -80%;
    }
    to{
        bottom: 0;
    }   
} */

.item-cont{
    display: flex;
    flex-direction: column;
    gap: 16px;
	flex: 1;
    overflow-y: auto;
}

.selector-container{
    height: 3px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding: 20px 0;
	touch-action: none;
}

.selector{
    height: 3px;
    width: 60px;
    border-radius: 6px;
    background: grey;
}
</style>