import VueRouter from 'vue-router'

import YYYYMMDD from './scripts/date.js'
import Vue from 'vue';

const routes = [{
        name: 'home',
        path: '/',
        component: () =>
            import ("./pages/Home.vue")
    },
    {
        name: 'auth',
        path: '/auth',
        component: () =>
            import ('./pages/Auth.vue')
    },
    {
        name: 'welcome',
        path: '/welcome',
        component: () =>
            import ('./pages/Welcome.vue')
    },
	{
		name: 'docs',
		path: '/docs/:docName',
		component: () => import ('./pages/LinksLayout.vue'),
	},
	{
		name: 'account-settings',
		path: '/account',
		component	: () => import ('./pages/AccountSettings.vue')
	},
    {
        name: 'calendar-layout',
        path: '/',
        component: () => import ('./pages/CalendarPage.vue'),
        children: [
            
			{
				name: 'calendar-error-not-found',
				path: ':calendarUid/error/not-found',
				component: () => 
					import('./pages/error-messages/NotFoundMessage.vue')
			},
			{
				name: 'calendar-error-restricted',
				path: ':calendarUid/error/restricted',
				component: () => 
					import('./pages/error-messages/RestrictedMessage.vue')
			},
			{
				name: 'calendar-schedule',
				path: '/:calendarUid',
				component: () =>
					import ('./pages/CalendarSchedule.vue')
			},
            {
                name: 'slot-edit',
                path: ':calendarUid/slots/:slotId(\\d+)/edit',
                component: () => import ('./pages/SlotEdit.vue')
            },
            {
                name: 'slot-attend',
                path: ':calendarUid/slots/:slotId(\\d+)',
                component: () => import ('./pages/SlotAttend.vue')
            },

            {
                name: 'calendar-settings-attenders-menu',
                path: ':calendarUid/attenders',
                component: () => import('./components/settings/AttendersMenu.vue')
            },
            {
                name: 'calendar-settings',
                path: ':calendarUid/settings',
                redirect: ':calendarUid/settings/menu',
                component: () => 
                    import ('./pages/CalendarSettings.vue'),
                children: [
                    {
                        name: 'calendar-settings-menu',
                        path: 'menu',
                        component: () => import('./components/settings/MainMenu.vue')
                    },
                    {
                        name: 'calendar-settings-main',
                        path: 'main',
                        component: () => import('./components/settings/MainSettings.vue')
                    },
                    {
                        name: 'calendar-settings-template-menu',
                        path: 'template',
                        component: () => import('./components/settings/TemplateMenu.vue')
                    },
                    {
                        name: 'calendar-settings-template',
                        path: 'template/:templateId',
                        component: () => import('./components/settings/TemplateSettings.vue')
                    },
                    {
                        name: 'calendar-settings-host-menu',
                        path: 'host',
                        component: () => import('./components/settings/HostMenu.vue'),
                    },
					{
						name: 'calendar-settings-host',
						path: 'host/:hostId',
						component: () => import('./components/settings/HostSettings.vue')
					},
                    {
                        name: 'calendar-settings-price-level',
                        path: 'price-level',
                        component: () => import('./components/settings/PriceLevelSettings.vue'),
                    },
                    {
                        name: 'calendar-settings-rating-menu',
                        path: 'rating',
                        component: () => import('./components/settings/RatingMenu.vue')
                    },
                ]
            }
        ]
    },
    {
        name: 'create-calendar',
        path: '/calendar/create',
        component: () =>
            import ('./pages/CreateCalendar.vue')
    },
    {
        name: 'calendar-list',
        path: '/calendar/list',
        component: () =>
            import ('./pages/CalendarList.vue'),
        query: {
            q: null,
        },
    },
    {
        name: 'test-card-settings',
        path: '/test/page',
        component: () =>
            import ('./components/forms/SlotSettings.vue')
    },
    {
        name: 'test-auth',
        path: '/test/auth',
        component: () =>
            import ('./pages/test/Auth.vue')
    }
]

const router = new VueRouter({
    routes: routes,
    mode: 'history'
})

// Navigation guard to store previous route information
router.beforeEach((to, from, next) => {
	// Store the previous route path in a Vue instance property
	Vue.prototype.$previousRoute = from;
	next();
});

export default router