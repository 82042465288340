let vw
let vh

function refreshCssUnits() {
	vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) / 100
	vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) / 100
	
	vw = vw.toFixed(3)
	vh = vh.toFixed(3)
}

refreshCssUnits()

export { vh as cssVh, vw as cssVw, refreshCssUnits }