<template>
    <div class="main__layout" >
        <Header v-if="!(!isLogin && (routeName == 'home' || isDocPage()))"/>
        <div class="layout__content" v-bind:class="{
			'without-static-menu': !(isLogin && isVisible),
			'without-header' : !isLogin && (routeName == 'home' || isDocPage()),
			'without-all' : !isLogin && (routeName == 'home' || isDocPage()) && !(isLogin && isVisible),
		}">
            <slot></slot>
            <CreateSlotModal :active="createSLotModalIsActive"/>
        </div>
        <StaticMenu v-if="isLogin" :isVisible="isVisible" />
    </div>
</template>

<script>
import Header from '../components/header/Header.vue';
import { globalBus } from '../GlobalEventBus';
export default {
    name: "MainLayout",
    components: {
        Header,
        CreateSlotModal : () => import('../components/modals/create-slot/CreateSlotModal.vue'),
        StaticMenu : () => import('../components/static-menu/StaticMenu.vue'),
    },
	created() {
        globalBus.$on("static-menu", "update-visible", ({visible}) => {
            this.isVisible = visible
        })
    },
	data() {
		return {
			isVisible: true,
		}
	},
    computed: {
        isLogin(){
            return this.$store.getters.userIsLogin 
        },
		createSLotModalIsActive(){
			return !!this.$route.query['m_cs']
		},
		routeName(){
			return this.$route.name
		}
    },
    methods: {
        navBarIsNeeded(){
            let result = true
            return result
        },
		isDocPage(){
			if (!this.$route.name)
				return false
			return this.$route.name.includes("doc")
		}
    },
}
</script>

<style scoped>
.layout__content{
    margin: 32px 0 0 0;
	height: calc(100dvh - 32px - 50px);
    overflow-y: auto;
    position: relative;
}

.without-static-menu{
	height: calc(100dvh - 32px);
}

.without-header{
	margin-top: 0;
}

.without-all{
	height: 100dvh;
}
</style>