function range(start, end) {
    let result = []
    for (let hour = start; hour < end; hour++) { 
        if (hour == 24) {
            break
        }
        result.push(hour)
    }
    return result
}

function calculateSupportWorkTime(hour, {workStart, workEnd}) {
    
    // If work hours includes this hour
    if (hour > workStart && hour < workEnd) {
        throw Error("Table-Storage Error: This hour is supported by this calendar")
    }
    
    let neededHours = []

    // If the unsupported-hour is smaller than the start-work-hour
    if (hour < workStart) {
        neededHours = range(hour, workStart)
    }
    // If the unsupported-hour is biggest than the end-work-hour
    else if (hour > workEnd) {
        neededHours = range(workEnd, hour+1)
    }
    return neededHours
}

function setSupportWorkTime({getters, commit}, {hour, index}) {

    let supportHours = [...getters.calendarSupportedHours]

    console.log('unsupported slot index :>> ', index);

    // If unsupported hour already in the array of support hours
    if (supportHours.includes(hour)) {
        commit('update-support-work-time', {
            hours: supportHours,
            slot: {
                index : index,
                hour: hour
            },
        })
        return
    }

    let workHours = getters.calendarWorkHours
    let workStart = workHours[0]
    let workEnd = workHours[workHours.length - 1]

    let neededHours = calculateSupportWorkTime(hour, {workStart,workEnd})
    console.log('needed hours :>> ', neededHours);

    // Add unique hours
    neededHours.forEach(hour => {
        if (workHours.includes(hour))
            return
        if (!supportHours.includes(hour)) {
            supportHours.push(hour)
        }
    })

    commit('update-support-work-time', {
        hours: supportHours,
        slot: {
            index : index,
            hour: hour
        },
    })

}

function updSupportWorkTime({getters, commit}, {index}) {

    // Check existing 
    let slot = document.getElementById(`new-card-${index}`)
    if (slot) {
        return
    }

    // Delete old unsupported slot
    commit('delete-support-slot', {index})
    console.log('state.supportedSlots :>> ', index, getters.calendarSupportedSlots);


    let workHours = getters.calendarWorkHours
    let workStart = workHours[0]
    let workEnd = workHours[workHours.length - 1]


    // Calculate new supported hours
    let newSupportedWorkTime = []
    let oldSupportedWorkTime = getters.calendarSupportedHours
    let supportedSlots = getters.calendarSupportedSlots
    supportedSlots.forEach( slot => {
        let neededHours = calculateSupportWorkTime(slot.hour, {workStart,workEnd})
        // Add unique hours
        neededHours.forEach(hour => {
            if (workHours.includes(hour))
                return
            if (!newSupportedWorkTime.includes(hour)) {
                newSupportedWorkTime.push(hour)
            }
        })
    })

    // Check no changes
    if (newSupportedWorkTime.length == oldSupportedWorkTime.length) {
        newSupportedWorkTime = newSupportedWorkTime.sort((a,b) => a-b)
        oldSupportedWorkTime = oldSupportedWorkTime.sort((a,b) => a-b)

        let isEqual = true
        for (let i=0; i < newSupportedWorkTime.length; i++) {
            if (newSupportedWorkTime[i] != oldSupportedWorkTime[i]) {
                isEqual = false
                break
            }
        }
        
        console.log('isEqual :>> ', isEqual);

        if (isEqual) {
            return
        }
    }


    commit('update-support-work-time', {
        hours: newSupportedWorkTime,
    })
}

function dateSlotsToToolbar({getters, commit}, {date}){
    
    let toolbarSlots = getters.tableSlotsToolBar
    let dateSlots = Object.values(getters.calendarSlotsByDate(date))
    if (!dateSlots || dateSlots.length == 0) return
    if (!toolbarSlots || toolbarSlots.length == 0){
        commit('add-slot-toolbar-date', {slots: dateSlots})
        return
    }
    
    let isAllSelected = true   
    dateSlots.forEach((slot) => {
        let isExists = toolbarSlots.find(toolbarSlot => {
            return toolbarSlot.index == slot.index
        })
        if (!isExists) isAllSelected = false
    })
    
    if (isAllSelected) {
        commit('del-slot-toolbar-date', {date})
        return
    }

    commit('add-slot-toolbar-date', {slots: dateSlots})
}

export {
    setSupportWorkTime,
    updSupportWorkTime,
    dateSlotsToToolbar,
}