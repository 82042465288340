const DURATION = 60 // 1 hour 
/**
 * Time period for the calendar cards
 */
class TimePeriod {

    /**
     * Default: 1 hour period
     * @param {Date} start - start of the time period
     * @param {Date} end - end of the time period
     * @param {Number} index - index of card
     * @param {Number} duration - time duration betwen start and end in minutes.
     */
    constructor(start, index, duration = DURATION, end) {

        if (!start) start = new Date()
        start.setSeconds(0)

        let localEnd = end
        if (!localEnd) {
            localEnd = new Date(start.getTime())
            localEnd.setMinutes(localEnd.getMinutes() + duration)
        }


        this.start = { date: start, time: start.getTime() }
        this.end = { date: localEnd, time: localEnd.getTime() }
        this.index = index || 'undefined'

        if (!end) {
            this.duration = duration
        } else {
            this.duration = (end.getTime() - start.getTime()) / 1000 / 60
        }

    }

    minute = () => this.start.date.getMinutes()
    hour = () => this.start.date.getHours()
    date = () => this.start.date.getDate()
    month = () => this.start.date.getMonth()
    year = () => this.start.date.getFullYear()

    /**
     * Checks whether a date belongs to a time period
     * @param {Date} date - date
     * @param {Boolean} strictly - is strict comparison
     */
    include(date, strictly = false) {
        let timestamp = date.getTime()
        return strictly ?
            this.start.time < timestamp && this.end.time > timestamp :
            this.start.time < timestamp && this.end.time > timestamp
    }

    /**
     * Checks for the intersection of two time periods
     * @param {TimePeriod} timePeriod - second time period
     * @param {Boolean} strictly - is strict comparison
     */
    intersection(timePeriod, strictly = false) {
        return this.include(timePeriod.start.date, strictly) || this.include(timePeriod.end.date, strictly)
    }

    /**
     * Return projection of time period on this date
     * @param {Date} date - date on which the projection will be made 
     */
    projection(date) {
        let period = this.copy()
        let projectionDate = new Date(date.getTime())

        projectionDate.setHours(period.hour(), period.minute())

        if (period.hour() == 0 && period.minute() == 0) {
            projectionDate.setDate(date.getDate() + 1)
        }

        period.update(projectionDate)

        return period
    }

    /**
     * Update time period
     * @param {Date} start - start of the time period
     */
    update(start) {
        start.setSeconds(0)
        let end = new Date(start.getTime())
        end.setMinutes(end.getMinutes() + this.duration)
        this.start = { date: start, time: start.getTime() }
        this.end = { date: end, time: end.getTime() }
    }

    /**
     * Shift by minutes
     * @param {Number} step - number of minutes to shift
     */
    shift(step) {
        let date = new Date(this.start.date.getTime())
        date.setMinutes(date.getMinutes() + step)
        this.update(date)
    }

    /**
     * Create a copy of this time period
     */
    copy() {
        return new TimePeriod(this.start.date, this.index, this.duration)
    }

    /**
     * Check equals TimePeriod
     * @param {TimePeriod} timePeriod - start of the time period
     */
    equals(timePeriod) {
        return this.hour() == timePeriod.hour() && this.minute() == timePeriod.minute()
    }

    /**
     * Return string with main time period data 
     */
    toString() {
        return ` ${this.date()}.${this.month()} ${this.start.date.toLocaleTimeString()} - ${this.end.date.toLocaleTimeString()}`
    }

    /**
     * Return period is past 
     */
    isPast() {
        let now = new Date()
        return now.getTime() > this.end.time
    }

	isEqual(timePeriod) {
		return this.start.time == timePeriod.start.time && this.end.time == timePeriod.end.time
	}

	updateDuration(duration) {
		this.duration = duration
		this.update(this.start.date)
		console.log("upd duration : ", this.toString())
	}	
}


export default TimePeriod