import client, { errorView } from "../../client"

class Settings {
	static getHosts(calendarUid) {
        return client.get(`/cms/api/v1/calendars/${calendarUid}/settings/hosts`)
			.then((response) => response.data)
			.catch(err => errorView("Get calendar hosts ", err))
		}
		
	static createHost(calendarUid, data){
		return client.post(`/cms/api/v1/calendars/${calendarUid}/settings/hosts`, data)
			.then((response) => response.data)
			.catch(err => errorView("Create host ", err))
			
	}
}

export default Settings