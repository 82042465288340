import {findStringBetween} from './string'
import Timezone from './timezone'

function YYYYMMDD(date) {
    let year = date.getFullYear()
    let day = date.getDate()
    if (day < 10) day = "0" + day

    let month = date.getMonth() + 1
    if (month < 10) month = "0" + month

    return `${year}-${month}-${day}`
}

function pair(number){
    return number > 9 ? number : "0" + number
}

function DDMMYYYY(date) {
    let year = date.getFullYear()
    let day = date.getDate()
    if (day < 10) day = "0" + day

    let month = date.getMonth() + 1
    if (month < 10) month = "0" + month

    return `${day}-${month}-${year}`
}

function getStringByFormat(format, date){
    let result = []
    let sepr = getFormatSeparator(format)
    let parts = format.split(sepr)

    parts.forEach(formatPart => {
        switch (formatPart) {
            case 'dd':
                result.push(pair(date.getDate()))
                break;

            case 'MM':
                result.push(pair(date.getMonth()+1))
                break;

            case 'YYYY':
                result.push(date.getFullYear())
                break;

            case 'YY':
                result.push(String(date.getFullYear()).slice(2))
        }
    })

    return result.join(sepr)
}

function getFormatSeparator(format){
    for (let index = 0; index < format.length; index++){
        let char = format[index]
        if (!/^[a-zA-Z0-9 ]*$/.test(char)){
            return char
        }
    }
}

function nextDate(date = new Date(), next = 1) {
    // Seconds in one day
    const inDay = 86400
    date.setHours(0, 0, 0)

    // Date to seconds
    let seconds = Math.round(date.getTime() / 1000)

    return new Date((seconds + next * inDay) * 1000)
}

function weekStart(date = new Date()) {
    // Seconds in one day
    const inDay = 86400


    // Set zero time
    date.setHours(0, 0, 0)

    // Get day of the week
    let dayWeek = date.getDay()

    // European format of the week
    if (dayWeek === 0) dayWeek = 6
    else dayWeek -= 1

    // Date to seconds
    let seconds = Math.round(date.getTime() / 1000)

    // Return Monday with time 00:00:00 of this week
    return new Date((seconds - dayWeek * inDay) * 1000)
}

function weekEnd(date = new Date()){
	const weekStartDate = weekStart(date)
	return nextDate(weekStartDate, 6)
}

function getCurrentWeek(date = new Date(), count = 7) {
    let week = []

    // where change column count
    if (count != 7) {
        let sideCount = Math.floor(count / 2)

        // left side
        for (let step = sideCount * (-1); step < 0; step++) {
            week.push(nextDate(date, step))
        }

        week.push(date)

        // right side
        for (let step = 1; step <= sideCount; step++) {
            week.push(nextDate(date, step))
        }
        return week
    }

    let start = weekStart(date)
    week.push(start)

    for (let step = 1; step < 7; step++) {
        week.push(nextDate(start, step))
    }

    return week
}

function getCurrentWeekV2(date = new Date(), count = 7) {
    let week = []
	for (let step = 0; step < count; step++) {
		week.push(nextDate(date, step))
	}
	return week
}

function dateIsPast(date){
    let now = new Date()
    return now.getTime() > date.getTime()
}

function UTCString(date)
{
    return date.toISOString().replace('.000', '')
}

/**
 * Convert date from one timezone to another one using timezone string
 * @param {Date} date date that you need to change the timezone
 * @param {Timezone} originTimezone origin date timezone string
 * @param {Timezone} newTimezone new date timezone string
 * @returns Converted date with new timezone
 */
function convertTZ(date, originTimezone, newTimezone) {

	const totalDifference = newTimezone.utcOffset - originTimezone.utcOffset

    const newDate = new Date(date)
    newDate.setMinutes(date.getMinutes() + totalDifference) 

    return newDate;   
}

/**
 * 
 * @param {string} timezone 
 * @returns {Number} hours difference between UTC and timezone. For example, timezone Japan (JST +9:00) return 9.
 */

function UTCHourDifference(timezone){
    // timezone example : Japan (JST +9:00)

    // find value between '(' and ')' : Japan (JST +9:00)  --> JST +9:00
    let shortTZ = findStringBetween(timezone, '(', ')')
    
    // check UTC and GMT
    if (shortTZ.includes("UTC") || shortTZ.includes("GMT") || shortTZ.includes("WET")){
        return 0
    }

    // JST +9:00 --> +9:00
    let timePart = shortTZ.split(" ")[1]
    let hour = ""


    // +9:00 --> +9 
    let chars = timePart.split("")
    for (let index = 0; index < chars.length; index++) {
        let currentChar = chars[index];
        if (currentChar == ":"){
            break
        }
        hour += currentChar
    }

    hour = Number(hour)

    return hour
}

function HHMMToMinutes(timeString) {
    const [hours, minutes] = timeString.split(":").map(Number);
    return hours * 60 + minutes;
}

function isValidHHMM(timeString) {
    const regex = /^([01]\d|2[0-3]):([0-5]\d)$/;
    return regex.test(timeString);
}

function minutesToHHMM(minutes) {
    let hours = Math.floor(minutes / 60);
    let remainingMinutes = minutes % 60;
    let hoursStr = hours < 10 ? "0" + hours : hours.toString();
    let minutesStr = remainingMinutes < 10 ? "0" + remainingMinutes : remainingMinutes.toString();
    return hoursStr + ":" + minutesStr;
}

function isEqualDate(date1, date2) {
    if (!date1 || !date2)
        return (false)
    return (
        date1.getDate() === date2.getDate() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getFullYear() === date2.getFullYear()
      );
}

/**
 * Checks the date for yesterday
 * @param {Date} date 
 */
function isYesterday(date) {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
  
    return isEqualDate(date, yesterday);
  }
  

/**
 * Checks the date for today
 * @param {Date} date 
 */
function isToday(date) {
    const today = new Date();

    return isEqualDate(date, today);
}

/**
 * Checks the date for tomorrow
 * @param {Date} date 
 */
function isTomorrow(date) {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
  
    return isEqualDate(date, tomorrow);
  }

function dateDifferenceInDays(date1, date2) {
    // Convert both dates to milliseconds
    const date1Milliseconds = date1.getTime();
    const date2Milliseconds = date2.getTime();

    // Calculate the difference in milliseconds
    const differenceMilliseconds = Math.abs(date1Milliseconds - date2Milliseconds);

    // Convert the difference back to days
    const differenceDays = Math.ceil(differenceMilliseconds / (1000 * 60 * 60 * 24));

    return Math.abs(differenceDays);
}

function getTimeString(date){
	if (!date) return ""
	return `${pair(date.getHours())}:${pair(date.getMinutes())}`
}

function dateRange(start, end){
	const result = []
	while (start <= end){
		result.push(new Date(start))
		start = nextDate(start)
	}
	return result
}

function smartDateSelect(date, daysCount){
			
	if (daysCount == 1)
		return date 
	else if (daysCount == 7)
		return weekStart(date)
	
	date = new Date(date)
	date.setHours(0, 0, 0, 0)

	const selectedRangeStart = new Date(date)
	const startDifference =	daysCount % 2 > 0 ?
		Math.floor(daysCount / 2) : Math.floor(daysCount / 2) - 1
	selectedRangeStart.setDate(selectedRangeStart.getDate() - startDifference)
	
	const selectedRangeEnd = new Date(date)
	const endDifference = Math.floor(daysCount / 2)
	selectedRangeEnd.setDate(selectedRangeEnd.getDate() + endDifference)

	const weekStartDate = weekStart(date)
	const weekEndDate = weekEnd(date)

	while (selectedRangeStart < weekStartDate){
		selectedRangeStart.setDate(selectedRangeStart.getDate() + 1)
		selectedRangeEnd.setDate(selectedRangeEnd.getDate() + 1)
	}
	while (selectedRangeEnd > weekEndDate) {
		selectedRangeStart.setDate(selectedRangeStart.getDate() - 1)
		selectedRangeEnd.setDate(selectedRangeEnd.getDate() - 1)
	}
	return selectedRangeStart
}

export default YYYYMMDD
export {  isYesterday, isToday, isTomorrow, isEqualDate, dateRange, getCurrentWeekV2, smartDateSelect, getTimeString, dateDifferenceInDays, pair, weekStart, weekEnd, getCurrentWeek, HHMMToMinutes, minutesToHHMM, isValidHHMM, nextDate, UTCString, convertTZ, dateIsPast, DDMMYYYY, getStringByFormat, getFormatSeparator, UTCHourDifference} 