<template>
    <div id='header'>
        <div class='title__container'>
            <span class="open_calendar_menu" v-if="headerTitle" @click="openPersonalCalendarList">
                <div class="title__value">{{headerTitle}}</div>
                <span class="iconify" 
                    data-icon="ic:round-keyboard-arrow-down" 
                    style="color: #969696;" 
                    data-width="22" 
                    data-height="22">
                </span>
            </span>
            <span v-else>
                Hora Guru
            </span>
        </div>
        <div class="reg" v-if="!isAuthPage">
            <div @click="auth"
                v-if="!isLogin">
                Authorize
            </div>
            <div style="height:21px"
            @click="openBurgerMenu" 
                v-else>
                <img src="@/assets/menu-outline.svg">
            </div>
        </div> 

        <CalendarBurgerMenu v-if="isLogin && isCalendarPage"
            :show='showBurgerMenu'
            :closer="closeBurgerMenu"
        />
        <DefaultBurgerMenu v-if="isLogin && !isCalendarPage"
            :show='showBurgerMenu'
            :closer="closeBurgerMenu"
        />
        <PersonalCalendarList v-if="isLogin"
            :title="personalCalendarListTitle"
            :show="showPersonalCalendarList"
            :selector="calendarSelector"
            :closer="closePersonalCalendarList"
            :excludeCurrent="excludeCurrent"
            :excludeCreate="excludeCreate"
        />
    </div>
</template>

<script>
import PersonalCalendarList from '../calendar-list/PersonalCalendarList.vue'
import TokenService from '../../service/token-service.js'

import router from '../../router'
import CalendarBurgerMenu from './CalendarBurgerMenu.vue'
import DefaultBurgerMenu from './DefaultBurgerMenu.vue'
import { globalBus } from '../../GlobalEventBus'

export default {
    name:'Header',
    components:{
        PersonalCalendarList,
        CalendarBurgerMenu,
        DefaultBurgerMenu
    },
    computed: {
        headerTitle(){
			const calendar = this.$store.getters.currentCalendar()
            if (!this.$store.getters.getDefaultCalendar || !calendar)
                return undefined

            if (!this.isCalendarPage)
                return "My calendar"


            let defaultCalendarUid = this.$store.getters.getDefaultCalendar

            if (calendar.uid == defaultCalendarUid) {
                return "My calendar"
            } else {
                return calendar.title
            }
        },

        isLogin(){
            return this.$store.getters.userIsLogin
        },
        isCalendarPage(){
            return this.$route.name == "calendar-schedule"
        }
    },
    beforeMount() {
        console.log('create header')
    },
    created(){
        globalBus.$on('page-header', 'open-personal-calendar-list', (payload) => {
            this.calendarSelector = (uid) => {
                this.calendarSelector = null
                this.excludeCurrent = false
                this.excludeCreate = false
                this.personalCalendarListTitle = ""
                return payload.selector(uid)
            }
            this.excludeCurrent = payload.excludeCurrent
            this.showPersonalCalendarList = true
            this.excludeCreate = payload.excludeCreate
            this.personalCalendarListTitle = payload.title
        })
    },
    data(){
        return {
            showBurgerMenu: false,
            showPersonalCalendarList: false,
            personalCalendarListTitle: "",
            excludeCurrent: false,
            excludeCreate: false,
            calendarSelector: null, 
        }
    },
    props:{
        isAuthPage:{
            type: Boolean,
            default: false,           
        }
    },
    methods: {
        auth() {
            router.push({name:'auth'})
        },

        openBurgerMenu(){
            this.showBurgerMenu = true
        },
        closeBurgerMenu(){
            this.showBurgerMenu = false
        },

        openPersonalCalendarList(){
            this.showPersonalCalendarList = true
        },
        closePersonalCalendarList(){
            this.personalCalendarListTitle = ""
            this.showPersonalCalendarList = false
        },
    },    
}
</script>

<style scoped>
#header{
    position: fixed;
    top:0;
    z-index: 6;
    height: fit-content;
    background: lightgray;
    font-size: 12px;
    font-weight: 400;
    height: 32px;
    width: 100%;
}
.open_calendar_menu{
    display: grid;
    grid-template-columns: calc(100% - 18px) 18px;
}
.title__container{
    float: left;
    width: fit-content;
    margin-left: 10px;
    font-weight: 700;
    display: flex;
    align-items: center;
    height: 100%;
    font-size: 15px;
}

.title__value{
    display: flex;
    align-items: center;
}
.reg{
    float: right;
    color: #4773e5;
    margin-right: 10px;
    text-decoration: underline;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: 100%;
}
</style>