import Vue from 'vue'
import table from './calendar-table-store'

const getDefaultState = () => {
    return {
		currentCalendarUid: "",
		calendars: {}
    }
}

export default {
    state : getDefaultState(),    
    modules: {
        table,
    },
    mutations: {
		'add-calendar': (state, calendarInstance) => {
			Vue.set(state.calendars, calendarInstance.uid, calendarInstance)
		},
		'set-current-calendar': (state, calendarUid) => {
			state.currentCalendarUid = calendarUid
		},
    },
    getters: {
		getCalendarByUid(state) {
			return (uid) => {
				return state.calendars[uid]
			}
		},
		currentCalendar(state) {
			return () => {
				return state.calendars[state.currentCalendarUid]
			} 
		},
		calendarTimezone(state, getters) {
			const currentCalendar = state.calendars[state.currentCalendarUid]
			if (!currentCalendar)
				return undefined

			const timezone = getters.getTimezoneByID(currentCalendar.timezoneId)

			return timezone
		},
    },
    actions: {
        'add-calendar': ({commit}, calendarInstance) => {
			commit('add-calendar', calendarInstance)
		},
		'set-current-calendar': ({commit}, calendarUid) => {
			commit('set-current-calendar', calendarUid)
		}
    }
}