/**
 * Service for the handling of utm attrs.
 */
class UTM {
    /**
     * Save utm attrs.
     *
     * @param {Object} query - route attrs
     */
    static save(query) {
        let utmAttrs = {}
        let utmCount = 0
        Object.keys(query).forEach(item => {
            if (item.includes('utm_')) {
                utmAttrs[item] = query[item]
                utmCount += 1
            }
        })
        console.log('utmAttrs', utmAttrs, utmCount)
        if (utmCount) localStorage.setItem("utm", JSON.stringify(utmAttrs))
    }
}

export default UTM