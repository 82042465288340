import Vue from "vue"
import { isEqualDate } from "../../scripts/date"

const getDefaultState = () => {
    return {
		tableHeader: null
    }
}

export default {
    state : getDefaultState(),    
    mutations: {
        'set-table-header': (state, header) => {
            state.tableHeader = header 
        },
		'update-table-week': (state, week) => {
			Vue.set(state.tableHeader, "week", week)
		},
		'update-table-selected-date': (state, selectedDate) => {
			Vue.set(state.tableHeader, "selectedDate", selectedDate)
		}
    },
    getters: {
        tableHeader(state){
            return state.tableHeader
        },
    },
    actions: {
        'set-table-header': (context, header) => {
            context.commit('set-table-header', header)
        },
		'update-table-selected-date': (context, {selectedDate}) => {
			return new Promise((resolve,) => {
				context.commit('update-table-selected-date', selectedDate)
			}) 
		},
		'update-table-week': (context, {selectedDate}) => {
			let tableHeader = context.getters.tableHeader
			return tableHeader.refresh(selectedDate)
				.then(week => {
					context.commit("update-table-week", week)
					return week
				})
		},
		'table-swipe-right': (context, {count}) => {
			let tableHeader = context.getters.tableHeader
			let pinnedDate = tableHeader.pinnedDate
			console.log('pinnedDate :>> ', pinnedDate);

			return tableHeader.pushRight(count, pinnedDate)
				.then(week => {
					context.commit("update-table-week", week)
					// tableHeader.updatePinnedDatePlace()
					return week
				})
		},
		'table-swipe-left': (context, {count}) => {
			let tableHeader = context.getters.tableHeader
			let pinnedDate = tableHeader.pinnedDate
			return tableHeader.pushLeft(count, pinnedDate)
				.then(week => {
					context.commit("update-table-week", week)
					// tableHeader.updatePinnedDatePlace()
					return week
				})
		},
    }
}