import router from "../router"

class Modal {
	constructor(name, openFunc, closeFunc){
		this.name = name
		this.$closeFunc = closeFunc
		this.$openFunc = openFunc
	}

	update(openFunc, closeFunc) {
		this.$closeFunc = closeFunc
		this.$openFunc = openFunc
	}

	open(){
		if (this.$openFunc)
			this.$openFunc()
		console.log(`Modal ${this.name} opened`)
	}
	
	close(){
		if (this.$closeFunc)
			this.$closeFunc()
		console.log(`Modal ${this.name} closed`)
	}
}

class ModalProvider {
	static openedModals = []
	static modals = []

	static addModal(name, opener, closer) {
		let modal = this.openedModals.find(modal => modal.name == name)
		if (modal){
			return modal.update(opener, closer)
		}
		this.modals.push(new Modal(name, opener, closer))
	}

	static openModal(name) {
		let modal = this.modals.find(modal => modal.name == name)
		if (!modal || this.openedModals.includes(modal))
			return false
		modal.open()
		this.openedModals.push(modal)
		return true
	}

	static isOpen(name) {
		return !!this.openedModals.find(modal => modal.name == name)
	}

	static closeModal(name) {
		if (this.openedModals.length == 0)
			return false
		let modal = this.modals.find(modal => modal.name == name)
		if (!modal)
			return false
		modal.close()
		this.openedModals = this.openedModals.filter(m => m.name != modal.name)
		return true
	}

	static back() {
		return router.back()
	}

	static closeLastModal(){
		if (this.openedModals.length == 0)
			return false
		let lastModalName = this.openedModals[this.openedModals.length - 1].name
		return (this.closeModal(lastModalName))
	}
}

export default ModalProvider